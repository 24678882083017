<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title print-hide">{{ title }}</h1>

    <div class="container-print">
      <b-field
        label="Correo a usar para enviar movimiento de material (Requerido)"
        expandeds
        label-position="inside"
        class="special-margin"
        v-if="sendClientEmail"
      >
        <b-select
          placeholder="Ejemplo: Ventas"
          icon="at"
          expanded
          v-model="selectedEmail"
        >
          <option value="" disabled>selecciona un correo</option>
          <option
            v-for="singleEmail in companyEmails"
            :value="singleEmail._id"
            :key="singleEmail._id"
          >
            {{ singleEmail.CORREO }}
          </option>
        </b-select>
      </b-field>
      <b-field
        label="Correos a donde enviar movimiento de material (Opcional)"
        expanded
        label-position="inside"
        class="special-margin required-input"
        v-if="sendClientEmail"
      >
        <b-taginput
          v-model="clientSendEmails"
          ellipsis
          icon="label"
          placeholder="Correos para envio de movimiento de material "
          :before-adding="(tagToAdd) => validateEmail(tagToAdd)"
        >
        </b-taginput>
      </b-field>
    </div>

    <div class="container-print" ref="saleQuote">
      <img
        v-if="companyInformation.LOGO_URL"
        :src="companyInformation.LOGO_URL"
        alt="Company logo"
        style="margin-top: 1rem; width: 20%; margin-bottom: 1rem"
      />
      <p class="special-margin">
        <strong>
          {{
            companyInformation.LEYENDA_SUPERIOR
              ? companyInformation.LEYENDA_SUPERIOR
              : ""
          }}</strong
        >
      </p>
      <table width="100%">
        <tr class="logotype-container">
          <td>
            <div class="logotype-type" :style="[backgroundStyle, textStyle]">
              {{ title }}
            </div>
          </td>
        </tr>
      </table>
      <br />
      <div style="margin-bottom: 20px">
        <strong>Información de la empresa</strong><br />
      </div>
      <table width="100%">
        <tr>
          <td>
            <table>
              <tr>
                <td>
                  <strong>Nombre:</strong>
                  {{ companyName }} <br />
                  <strong>Dirección:</strong>
                  {{ companyInformation.DOMICIL }}
                  {{ companyInformation.NOMBRE_MUNICIPIO }},
                  {{ companyInformation.ESTADO }}
                  {{ companyInformation.CP }}<br />
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <br />
      <div
        style="padding-left: 20px; padding-top: 10px; padding-bottom: 10px"
        :style="backgroundStyle"
      >
        <strong :style="textStyle">Información del movimiento</strong><br />
      </div>
      <table width="100%">
        <tr>
          <td style="padding: 20px; width: 50%">
            <strong>#{{ title }}:</strong>
            {{ movementInformation.CLAVE_TRAN }}<br />
            <strong>Fecha:</strong>
            {{
              movementInformation.FECHA_TRAN_FOR
                ? movementInformation.FECHA_TRAN_FOR
                : getFormattedDate(movementInformation.FECHA_TRAN)
            }}<br />
            <strong>Almacen origen:</strong>
            {{
              movementInformation.ORIG_ALM
                ? movementInformation.ORIG_ALM
                : "NA"
            }}<br />
            <strong>Almacen destino:</strong>
            {{
              movementInformation.DEST_ALM
                ? movementInformation.DEST_ALM
                : "NA"
            }}<br />
          </td>
          <td style="padding: 20px; width: 50%">
            <strong>Tipo de movimiento:</strong>
            {{ movementInformation.TIPO_FOR }}<br />
            <strong>Usuario movimiento:</strong>
            {{ movementInformation.REALIZO_MOV }}<br />
            <strong>Estatus:</strong>
            {{ movementInformation.STATUS_FOR }}<br />
          </td>
        </tr>
      </table>
      <br />
      <div class="table-overflow">
        <table
          width="100%"
          style="
            border-collapse: collapse;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          "
        >
          <tr>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              #
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Clave
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Descripción
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Cantidad antes del movimiento
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Cantidad movida
            </td>
            <td class="column-header" :style="[backgroundStyle, textStyle]">
              Cantidad final
            </td>
          </tr>
          <div class="display-content">
            <tr class="article-element">
              <td class="row">{{ 1 }}</td>
              <td class="row">
                {{ movementInformation.CLAVE_ART.CLAVE_ART }}
              </td>
              <td class="row">
                {{ movementInformation.CLAVE_ART.DESCRIPCION }}
              </td>
              <td class="row">
                {{ movementInformation.SALDO_U }}
              </td>
              <td class="row">{{ movementInformation.CANTIDAD }}</td>
              <td class="row">{{ movementInformation.CANTIDAD_FIN }}</td>
            </tr>
          </div>
        </table>
        <br />
      </div>
      <div
        class="alert not-cut-printing"
        style="border-radius: 5px"
        :style="backgroundStyle"
      >
        <strong :style="textStyle">Observaciones: </strong> <br />
        <br />
        <b-input
          v-if="!printing"
          type="textarea"
          v-model="movementInformation.OBSER_TRAN"
        ></b-input>
        <div
          v-if="printing"
          class="observations-element observations-element-printing not-cut-printing"
          style="background-color: white; padding: 0.625em; min-height: 120px"
        >
          <p>{{ movementInformation.OBSER_TRAN }}</p>
        </div>
      </div>
      <p class="special-margin-top not-cut-printing">
        <strong>
          {{
            companyInformation.LEYENDA_INFERIOR
              ? companyInformation.LEYENDA_INFERIOR
              : ""
          }}</strong
        >
      </p>
      <div v-if="printing" style="margin-top: 1.5rem" class="not-cut-printing">
        Este pdf fue impreso por coconutcontrol.com
      </div>
    </div>
    <div class="container-print">
      <div class="add-global-controls">
        <b-button type="is-success" @click="print()">Descargar</b-button>

        <b-tooltip
          v-if="
            (!selectedEmail || clientSendEmails.length === 0) && sendClientEmail
          "
          label="Primero debes seleccionar un correo de donde enviar el movimiento de material y uno o más correos de destino"
          multilined
        >
          <b-button type="is-success" disabled>Enviar</b-button>
        </b-tooltip>

        <b-button
          type="is-success"
          @click="sendMaterialMovement()"
          v-if="
            selectedEmail &&
            clientSendEmails.length > 0 &&
            sendClientEmail &&
            companyPaymentPlanModules &&
            companyPaymentPlanModules.global.sendMaterialMovement === 'S' &&
            permissions &&
            permissions.global.sendMaterialMovement === 'S'
          "
          >Enviar</b-button
        >

        <b-button type="is-danger" @click="closeModal()" class="cancel-button"
          >Regresar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import { openConfirmationModalSucces } from "../../../../utils/component-fns";
import { hexToRGB } from "../../../../utils/fns";
// @ is an alias to /src
export default {
  name: "PrintMaterialMovement",
  props: ["movementInformation", "title"],
  components: {},
  data() {
    return {
      printing: false,
      printWithoutPrices: false,
      clientSendEmails: [],
      selectedEmail: "",
      sendClientEmail: true,
      moment: moment,
    };
  },
  mounted() {
    moment.locale("es");

    if (this.$store.getters.COMPANIES_EMAILS.length > 0) {
      this.selectedEmail = this.$store.getters.COMPANIES_EMAILS[0]._id;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc print the purchase order with the desire format
     */
    async print() {
      this.createPdfHtml();
      setTimeout(() => {
        this.printWithoutPrices = false;
      }, 500);
    },
    /**
     * @desc send the material movements to the provided emails
     */
    async sendMaterialMovement() {
      let pdfContent = await this.createPdfHtml();
      let response = await this.$store.dispatch("SENDPDFFILEMATERIALMOVEMENT", {
        clientEmails: this.clientSendEmails,
        selectedEmail: this.selectedEmail,
        fileName: `${this.title} ${this.movementInformation.CLAVE_TRAN} ${this.companyName}.pdf`,
        pdfFile: pdfContent,
      });
      if (response == "Success") {
        this.$buefy.dialog.confirm({
          title: "Éxito",
          message: "Se envió correctamente el movimiento de material",
          confirmText: "Entendido",
          type: "is-success",
          hasIcon: true,
          onConfirm: () => {
            this.closeModal();
          },
          cancelText: "cancelar",
          canCancel: false,
        });
      } else {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al enviar el movimiento de material",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc cretes or generates a pdf file
     */
    async createPdfHtml() {
      this.printing = true;
      let pdfOptions = {
        filename: `${this.title} ${this.movementInformation.CLAVE_TRAN} ${this.companyName}.pdf`,
        pagebreak: {
          avoid: [
            ".article-element",
            ".observations-element",
            ".not-cut-printing",
          ],
        },
        scale: 2,
        image: { type: "jpeg", quality: 0.98 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        margin: [0, 0.2, 0, 0.2],
      };
      let result = await html2pdf()
        .set(pdfOptions)
        .from(this.$refs.saleQuote)
        .save()
        .output("datauristring");
      this.printing = false;
      return result;
    },
    formatCreationDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     */
    startConfirmEvent(type, functionOnConfirm, name) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalSucces.call(this, type, functionOnConfirm, name);
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
  computed: {
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    companyName() {
      return this.$store.getters.COMPANY_INFORMATION.name;
    },
    companyEmails() {
      return this.$store.getters.COMPANIES_EMAILS;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    backgroundStyle() {
      return {
        "background-color": this.companyInformation.FONDO
          ? hexToRGB(this.companyInformation.FONDO, 1)
          : "rgba(0, 0, 0, 0.045)",
      };
    },
    backgroundSecondaryStyle() {
      return {
        "background-color": this.companyInformation.FONDO_SECUNDARIO
          ? hexToRGB(this.companyInformation.FONDO_SECUNDARIO, 1)
          : "rgba(0, 0, 0, 0.045)",
      };
    },
    textStyle() {
      return {
        color: this.companyInformation.TEXTO
          ? hexToRGB(this.companyInformation.TEXTO, 1)
          : "#363636",
      };
    },
    textSecondaryStyle() {
      return {
        color: this.companyInformation.TEXTO_SECUNDARIO
          ? hexToRGB(this.companyInformation.TEXTO_SECUNDARIO, 1)
          : "#363636",
      };
    },
    getFormattedDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
  },
  watch: {
    purchaseOrderInformation() {
      if (this.purchaseOrderInformation.PRV.EMAIL) {
        this.clientSendEmails.push(this.purchaseOrderInformation.PRV.EMAIL);
      }
    },
    companyEmails() {
      this.selectedEmail = this.companyEmails[0]._id;
    },
  },
};
</script>

<style scoped>
@import "./style/PrintPurchaseOrder.css";
@import "../../style/Global.css";
</style>
