var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container add-global-modal-main-container"},[_c('h1',{staticClass:"add-global-modal-main-title"},[_vm._v(" Transferir artículos entre almacenes ")]),_c('div',{staticClass:"control add-global-controls"},[_c('b-button',{staticClass:"special-margin",attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.toogleShowEditted()}}},[_vm._v(_vm._s(_vm.showOnlyEditted ? "Mostrar todos los artículos" : "Mostrar solo artículos editados"))])],1),_c('div',{staticClass:"invoice-add-item special-margin"},[_c('b-field',{staticClass:"invoice-add-secondary-item-three",attrs:{"label-position":"inside","label":"Almacen origen"}},[_c('b-select',{attrs:{"placeholder":"Ejemplo: Almacen 1","icon":"warehouse","expanded":"","disabled":_vm.newWarehouseTransfer.CVEALM1 !== ''},on:{"input":function (value) { return _vm.firstWarehouseSelected(value); }},model:{value:(_vm.newWarehouseTransfer.CVEALM1),callback:function ($$v) {_vm.$set(_vm.newWarehouseTransfer, "CVEALM1", $$v)},expression:"newWarehouseTransfer.CVEALM1"}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Seleccione un almacén")]),_vm._l((_vm.warehouses),function(warehouse){return _c('option',{key:warehouse.CLAVE_ALMACEN,domProps:{"value":warehouse}},[_vm._v(" "+_vm._s(warehouse.NOMBRE_ALM)+" ")])})],2)],1),_c('b-field',{staticClass:"invoice-add-secondary-item-three",attrs:{"label-position":"inside","label":"Almacen destino"}},[_c('b-select',{attrs:{"placeholder":"Ejemplo: Almacen 1","icon":"warehouse","expanded":"","disabled":_vm.newWarehouseTransfer.CVEALM1 === '' ||
          _vm.newWarehouseTransfer.CVEALM2 !== ''},on:{"input":function (value) { return _vm.secondWarehouseSelected(value); }},model:{value:(_vm.newWarehouseTransfer.CVEALM2),callback:function ($$v) {_vm.$set(_vm.newWarehouseTransfer, "CVEALM2", $$v)},expression:"newWarehouseTransfer.CVEALM2"}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Seleccione un almacén")]),_vm._l((_vm.warehouses),function(warehouse){return (warehouse !== _vm.newWarehouseTransfer.CVEALM1)?_c('option',{key:warehouse.CLAVE_ALMACEN,domProps:{"value":warehouse}},[_vm._v(" "+_vm._s(warehouse.NOMBRE_ALM)+" ")]):_vm._e()})],2)],1),_c('b-field',{staticClass:"invoice-add-secondary-item-three",attrs:{"label-position":"inside","label":"Fecha"}},[_c('b-datepicker',{attrs:{"show-week-number":true,"date-formatter":function (date) { return _vm.moment(date).format('DD/MM/YYYY'); },"day-names":['D', 'L', 'M', 'M', 'J', 'V', 'S'],"month-names":[
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre' ],"placeholder":"Fecha de creación","icon":"calendar-today","trap-focus":""},model:{value:(_vm.newWarehouseTransfer.FECHA_TRAN),callback:function ($$v) {_vm.$set(_vm.newWarehouseTransfer, "FECHA_TRAN", $$v)},expression:"newWarehouseTransfer.FECHA_TRAN"}})],1),_c('b-field',{staticClass:"invoice-add-secondary-item-one",attrs:{"label-position":"inside","label":"Observaciones"}},[_c('b-input',{attrs:{"type":"textarea","expanded":""},model:{value:(_vm.newWarehouseTransfer.OBSER_TRAN),callback:function ($$v) {_vm.$set(_vm.newWarehouseTransfer, "OBSER_TRAN", $$v)},expression:"newWarehouseTransfer.OBSER_TRAN"}})],1)],1),(
      _vm.newWarehouseTransfer.CVEALM1 !== '' &&
      _vm.newWarehouseTransfer.CVEALM2 !== ''
    )?_c('div',{staticClass:"invoice-div-bottom-div"},[_c('b-table',{ref:"TransferArticlesTable",attrs:{"pagination-position":"both","data":_vm.articlesFormatted,"striped":true,"bordered":true,"hoverable":true,"paginated":"","current-page":_vm.currentPage,"per-page":_vm.perPage,"opened-detailed":_vm.defaultOpenedDetails,"selected":_vm.selected,"sort-icon":"arrow-up","sort-icon-size":"is-small","default-sort-direction":"asc","checkbox-position":"left","default-sort":"CLAVE_ART","detailed":"","detail-key":"_id"},on:{"update:selected":function($event){_vm.selected=$event},"details-open":function (row) { return _vm.$buefy.toast.open({
            message: ("Información de " + (row.CLAVE_ART)),
            type: 'is-success',
            position: 'is-bottom',
          }); }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_vm._v("No hay almacenes or árticulos")])]},proxy:true},{key:"default",fn:function(props){return [_vm._l((_vm.columns),function(column){return _c('b-table-column',_vm._b({key:column.field,attrs:{"searchable":"","sortable":"","visible":column.display,"label":column.label}},'b-table-column',column,false),[_vm._v(" "+_vm._s(props.row[column.field])+" ")])}),_vm._l((props.row.defaultWarehouseArticles),function(column){return _c('b-table-column',_vm._b({key:column.CLAVE_ALMACEN,attrs:{"sortable":"","visible":column.display,"label":column.NOMBRE_ALM,"custom-sort":column.customSort}},'b-table-column',column,false),[(_vm.notFirstWarehouse(column))?_c('b-field',{staticClass:"special-simple-margin",attrs:{"label":"Artículos en almacén de origen","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 40","expanded":"","icon":"numeric-1-circle-outline","type":"number","step":"any","disabled":""},model:{value:(column.CANTIDAD),callback:function ($$v) {_vm.$set(column, "CANTIDAD", $$v)},expression:"column.CANTIDAD"}})],1):_vm._e(),(!_vm.notFirstWarehouse(column))?_c('b-field',{staticClass:"special-simple-margin",attrs:{"label":"A transferir a almacén de destino","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 40","expanded":"","icon":"numeric-1-circle-outline","type":"number","step":"any"},on:{"input":function (value) { return _vm.changeTransfer(
                    props.row,
                    _vm.newWarehouseTransfer.CVEALM1,
                    value
                  ); }},model:{value:(column.CANTIDAD),callback:function ($$v) {_vm.$set(column, "CANTIDAD", $$v)},expression:"column.CANTIDAD"}})],1):_vm._e()],1)})]}},{key:"detail",fn:function(props){return [_c('article',{staticClass:"media"},[_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image is-64x64"})]),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[_c('div',[_c('b-field',{attrs:{"label":"Presentación","label-position":"inside","expanded":""}},[_c('b-select',{staticClass:"special-margin",attrs:{"placeholder":"Ejemplo: Caja de 5 martillos","icon":"tape-measure","expanded":""},model:{value:(props.row.selectedPresentation),callback:function ($$v) {_vm.$set(props.row, "selectedPresentation", $$v)},expression:"props.row.selectedPresentation"}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione una presentación")]),_vm._l((props.row.PRESENTACIONES),function(presentation){return _c('option',{key:presentation.NOMBRE,domProps:{"value":presentation._id}},[_vm._v(" "+_vm._s(presentation.NOMBRE)+" ")])})],2)],1)],1),_vm._l((props.row.PRESENTACIONES),function(singlePresentation){return (
                  singlePresentation._id === props.row.selectedPresentation
                )?_c('div',{key:singlePresentation._id,staticClass:"special-big-margin single-articles-prices"},[_c('h3',{staticClass:"title-expandable-item"},[_c('strong',[_vm._v("Presentación ")]),_c('strong',[_c('span',{staticClass:"global-form-hint-color"},[_vm._v(_vm._s(singlePresentation.NOMBRE))])])]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Valor de medida base del artículo (Requerido)","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 40","expanded":"","icon":"numeric-1-circle-outline","type":"number","step":"any","value":singlePresentation.FACTOR1,"disabled":""}})],1),_c('b-field',{attrs:{"label":"Cantidad de medidas base que equivale la presentación (Requerido)","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 40","expanded":"","icon":"numeric-2-circle-outline","type":"number","step":"any","value":singlePresentation.FACTOR2,"disabled":""}})],1)],1),_c('b-field',{staticClass:"special-simple-margin",attrs:{"label":"A transferir a almacén de destino","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 40","expanded":"","icon":"numeric-1-circle-outline","type":"number","step":"any"},on:{"input":function (value) { return _vm.changeTransferPresentation(
                          props.row,
                          _vm.newWarehouseTransfer.CVEALM1,
                          value
                        ); }}})],1)],1):_vm._e()})],2)])])]}}],null,false,895205071)})],1):_vm._e(),_c('div',{staticClass:"add-global-controls"},[(
        _vm.companyPaymentPlanModules &&
        _vm.companyPaymentPlanModules.purchase.transferArticlesPending === 'S' &&
        _vm.permissions &&
        _vm.permissions.purchase.transferArticlesPending === 'S'
      )?_c('b-button',{attrs:{"type":"is-success","disabled":_vm.newWarehouseTransfer.CVEALM1 === '' ||
        _vm.newWarehouseTransfer.CVEALM2 === ''},on:{"click":function($event){return _vm.transferArticles(false)}}},[_vm._v("Crear transferencia pendiente")]):_vm._e(),(
        _vm.companyPaymentPlanModules &&
        _vm.companyPaymentPlanModules.purchase.transferArticles === 'S' &&
        _vm.permissions &&
        _vm.permissions.purchase.transferArticles === 'S'
      )?_c('b-button',{attrs:{"type":"is-success","disabled":_vm.newWarehouseTransfer.CVEALM1 === '' ||
        _vm.newWarehouseTransfer.CVEALM2 === ''},on:{"click":function($event){return _vm.transferArticles(true)}}},[_vm._v("Transferir artículos")]):_vm._e(),_c('b-button',{staticClass:"cancel-button",attrs:{"type":"is-danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Regresar")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }